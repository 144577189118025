import React, { Component } from 'react';
import { captureException as SentryCaptureException } from '@sentry/browser';

type Props = {};
type State = typeof initialState;

const initialState = Object.freeze({
  hasError: false,
});

export class ErrorBoundary extends Component<Props, State> {
  readonly state = initialState;

  componentDidCatch(error: Error, info: any) {
    this.setState({ hasError: true });

    if (process.env.NODE_ENV === 'production') {
      SentryCaptureException(error);
    }
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong</div>;
    }

    return this.props.children;
  }
}
