import React, { useState } from 'react';
import { IconApplePay, IconGooglePay, IconPaymentMastercard, UiButton, UiText } from '../../ui';
import { ICardInfoData } from '../../store/modules';
import { Popup } from '../../components';

interface Props {
  cardInfo: ICardInfoData;
  activeSession?: boolean;
  error?: string;
  onApplePay?: () => void;
  onGooglePay?: () => void;
  onReattach?: () => void;
  onDetach?: () => Promise<void>;
}

export const PaymentMethodExist = ({
  cardInfo,
  activeSession,
  error,
  onApplePay,
  onGooglePay,
  onReattach,
  onDetach,
}: Props) => {
  const [showPopup, setValue] = useState(false);

  const handleOpenPopup = () => setValue(true);
  const handleClosePopup = () => setValue(false);

  const handleDetach = () => {
    if (onDetach) !activeSession && onDetach();
  };

  return (
    <>
      <div className="bPaymentCard mb-40">
        <div className="bPaymentCard__paymentSystemLogo">
          {/* <IconPaymentMastercard className="icon icon_sizeFull" /> */}
        </div>
        <div className="bPaymentCard__number">
          <span className="bPaymentCard__hiddenSymbols">•••• •••• •••• </span>
          {cardInfo.card_number}
        </div>
        <span className="bPaymentCard__name">{cardInfo.card_holder}</span>
      </div>
      <UiButton className="btn_borderGreen btn_widthFull" onClick={onReattach}>
        Изменить карту
      </UiButton>
      {error && (
        <div className="row mb-16">
          <div className="col-12 textCenter">
            <UiText color="red">{error}</UiText>
          </div>
        </div>
      )}
      {onApplePay && (
        <UiButton onClick={onApplePay} className="btn_widthFull btn_borderBlack btn_bgTransparent mt-16">
          <IconApplePay />
        </UiButton>
      )}
      {onGooglePay && (
        <UiButton onClick={onGooglePay} className="btn_widthFull btn_borderBlack btn_bgBlack mt-16">
          <IconGooglePay />
        </UiButton>
      )}
      <UiButton className="btn_borderRed btn_widthFull mt-16" onClick={handleOpenPopup} disabled={activeSession}>
        Удалить карту
      </UiButton>
      <Popup show={showPopup} onCloseModal={handleClosePopup}>
        <>
          <h3 className="headline h1 mt-4 textCenter">Удалить карту?</h3>
          <div className="aligner">
            <UiButton className="btn_borderRed btn_widthFull mr-12" onClick={handleDetach}>
              Удалить
            </UiButton>
            <UiButton className="btn_borderGreen btn_widthFull" onClick={handleClosePopup}>
              Отмена
            </UiButton>
          </div>
        </>
      </Popup>
    </>
  );
};
